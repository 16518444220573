<template>
  <div style="width: 100%">
    <!-- 报餐营收饼状图 -->
    <div
      v-if="id == 'bcysDay'"
      style="width: 100%; height: 230px"
      id="bcysDay"
      :data="echartOption"
    ></div>
    <!-- 报餐营收柱状图 -->
    <div
      v-if="id == 'bcysSection'"
      style="width: 100%; height: 230px"
      id="bcysSection"
      :data="echartOption"
    ></div>
    <!-- 扫码营收饼状图 -->
    <div
      v-if="id == 'smysDay'"
      style="width: 100%; height: 180px"
      id="smysDay"
      :data="echartOption"
    ></div>
    <!-- 扫码营收柱状图 -->
    <div
      v-if="id == 'smysSection'"
      style="width: 100%; height: 180px"
      id="smysSection"
      :data="echartOption"
    ></div>
    <!-- 小卖部营收饼状图 -->
    <div
      v-if="id == 'smbsy'"
      style="width: 100%; height: 180px"
      id="smbsy"
      :data="echartOption"
    ></div>
    <!-- 小卖部营收柱状图 -->
    <div
      v-if="id == 'smbsySection'"
      style="width: 100%; height: 180px"
      id="smbsySection"
      :data="echartOption"
    ></div>
    <!-- 系统人数 -->
    <div
      v-if="id == 'systemPerson'"
      style="width: 100%; height: 300px"
      id="systemPerson"
      :data="echartOption"
    ></div>
    <!-- 部门柱状图 -->
    <div
      v-if="id == 'office'"
      style="width: 100%; height: 150px"
      id="office"
      :data="echartOption"
    ></div>
    <!-- 职务柱状图 -->
    <div
      v-if="id == 'post'"
      style="width: 100%; height: 150px"
      id="post"
      :data="echartOption"
    ></div>
    <!-- 好评分布柱状图 -->
    <div
      v-if="id == 'goodPraise'"
      style="width: 100%; height: 209px"
      id="goodPraise"
      :data="echartOption"
    ></div>
    <!-- 扫码情况柱状图 -->
    <div
      v-if="id == 'scanCase'"
      style="width: 100%; height: 210px"
      id="scanCase"
      :data="echartOption"
    ></div>
    <!-- 小卖部情况 -->
    <div
      v-if="id == 'shopCase'"
      style="width: 100%; height: 210px"
      id="shopCase"
      :data="echartOption"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    echartOption: {
      handler(newValue, oldValue) {
        this.drawEchart(this.id, newValue);
      },
      deep: true,
    },
  },
  props: ["id", "echartOption"],
  computed: {},
  components: {},
  created() {},
  mounted() {
    // 添加延时解决图表初始化时宽度超出父容器的问题
    this.$nextTick(() => {
      setTimeout(() => {
        this.drawEchart(this.id, this.echartOption);
      }, 1000);
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.clear();
    }
  },
  methods: {
    drawEchart(id, data) {
      // 新建一个promise对象
      let newPromise = new Promise((resolve) => {
        resolve();
      });
      //然后异步执行echarts的初始化函数
      newPromise.then(() => {
        let myChart = document.getElementById(id);
        this.chart = echarts.init(myChart);
        //判断是否有数据
        if (
          id == "smysSection" ||
          id == "bcysSection" ||
          id == "smbsySection"
        ) {
          //报餐营收柱状图和扫码营收柱状图和小卖部营收柱状图空数据判断
          if (data.series.length == 0) {
            this.chart.showLoading({
              text: "暂无相关数据",
              showSpinner: false,
              fontSize: 20,
            });
          } else {
            this.chart.hideLoading();
          }
        } else {
          //其余图表空数据判断
          if (data.series[0].data.length == 0) {
            this.chart.showLoading({
              text: "暂无相关数据",
              showSpinner: false,
              fontSize: 20,
            });
          } else {
            this.chart.hideLoading();
          }
        }

        setTimeout(() => {
          this.chart.setOption(data);
        }, 500);

        window.addEventListener("resize", () => {
          this.chart.resize();
        });
      });
    },
  },
};
</script>
<style lang='less' scoped>
</style>
